<template>

  <v-container
    fluid
    class="ma-0 pa-0"
    >

    <Confirm ref="confirm" />

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'network'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

         <!--<v-list-item class="pt-0 px-2">-->

          <v-list-item class="overline pt-0 mt-0 pl-1">
            Network
            <v-spacer />

            <div class="pl-2">
              <ChangeNetworkDialog :networkId="networkId" />

              <v-tooltip bottom v-if="terminals.length == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    :loading="deleting"
                    @click="deleteNetwork">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Network</span>
              </v-tooltip>
            </div>
          </v-list-item>

          <v-card
            outlined
            class="pa-0 ma-0 mt-2 flex-grow-1"
            style="">

            <v-card-title class="py-2">
              {{ network.name }}
              <v-spacer />
              <v-icon color="red">
                <template v-if="channel.inactive && network.inactive">mdi-minus-circle-multiple</template>
                <template v-else-if="channel.inactive">mdi-minus-circle-multiple-outline</template>
                <template v-else-if="network.inactive">mdi-minus-circle-outline</template>
              </v-icon>

            </v-card-title>

            <v-card-text class="pb-3">
              <template v-if="network.address">
                {{ network.address }}
              </template>
              <template v-else-if="!network.city || !network.country || !network.zip">
                <em>No Address</em>
              </template>

              <template v-if="network.city && network.country">
                <br />
                {{ network.city }}, {{ countryName }}
              </template>

              <template v-else-if="network.city">
                <br />
                {{ network.city }}
              </template>

              <template v-else-if="network.country">
                <br />
                {{ countryName }}
              </template>

              <template v-if="network.zip">
                <br />
                {{ network.zip }}
              </template>

              <template v-if="network.email">
                <br />
                {{ network.email }}
              </template>

              <template v-if="network.phone">
                <br />
                {{ formatPhone(network.phone) }}
              </template>
            </v-card-text>

            <v-card-text class="pt-0 px-0">
              <v-simple-table dense style="color: grey;">
              <tbody>
                <tr>
                  <td>Time Zone</td>
                  <td class="pl-0">{{ timezoneName }}</td>
                </tr>
                <tr>
                  <td>Currency</td>
                  <td class="pl-0">{{ network.currency }}</td>
                </tr>
                <tr>
                  <td>Tax</td>
                  <td class="pl-0">{{ network.tax_percentage }}%</td>
                </tr>
                <tr>
                  <td>Load Min / Max</td>
                  <td class="pl-0">
                    <span v-if="network.load_limit_min && network.currency">
                    {{ formatCurrency(network.load_limit_min, network.currency) }} / {{ formatCurrency(network.load_limit_max, network.currency) }}
                    </span>
                    <span v-else>
                      <em>Load Limit or Currency not found</em>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Cashback Min / Max</td>
                  <td class="pl-0">
                    <span v-if="network.cashback_limit_min && network.currency">
                    {{ formatCurrency(network.cashback_limit_min, network.currency) }} / {{ formatCurrency(network.cashback_limit_max, network.currency) }}
                    </span>
                    <span v-else>
                      <em>Cashback Limit or Currency not found</em>
                    </span>
                  </td>
                </tr>
              </tbody>
              </v-simple-table>
            </v-card-text>

          </v-card>

          <v-list
            v-model="terminalSelectedIndex">

            <v-list-item class="overline pt-0 mt-0 pl-1">
              Network Terminals
              <v-spacer />

              <NewTerminalDialog :networkId="networkId" />
            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name, Address, Email, Phone, Fees"
                v-model="terminalFilter"
                @click:clear="clearTerminalFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="terminal in filteredTerminalList"
              :key="terminal.id"
              link
              :to="{name: 'terminal', params: { terminal_id: terminal.id }}">

              <v-list-item-content>
                <v-list-item-title>
                  {{ terminal.name }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="terminal.terminal_users_count">
                  Authorized Clerks {{ terminal.terminal_users_count }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  <em>No Authorized Clerks</em>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon color="red">
                  <template v-if="(!channel.active || !network.active) && !terminal.active">mdi-minus-circle-multiple</template>
                  <template v-else-if="!channel.active || !network.active">mdi-minus-circle-multiple-outline</template>
                  <template v-else-if="!terminal.active">mdi-minus-circle-outline</template>
                </v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-1 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import NewTerminalDialog from '@/components/NewTerminalDialog.vue'
import ChangeNetworkDialog from '@/components/ChangeNetworkDialog.vue'
import ct from 'countries-and-timezones'
import Confirm from '@/components/Confirm.vue'

export default {
  data() {
    return {
      deleting: false,

      channelId: undefined,
      channel: {},

      terminalFilter: '',
      terminalSelectedIndex: undefined,
      terminalSelected: 0,
      filteredTerminalList: [],

      networkId: undefined,
      network: {},

      newTerminalDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Networks',
    }
  },

  computed: {
    ...mapGetters('Channel', ['channels', 'channelById', 'terminals', 'networks', 'networkById', 'terminalsByNetworkId']),

    countryName() {
      return ct.getCountry(this.network.country)?.name
    },

    terminals() {
      return this.terminalsByNetworkId(this.network.id) ?? []
    },

    timezonesByUtcOffsetStr() {
      console.log(ct.getAllTimezones())
      const d = Object.fromEntries(Object.entries(ct.getAllTimezones()).map(args => {
        return [args[1].utcOffsetStr, args[1]]
      }
        ))
      return d
    },

    timezoneName() {
      const tz = this.timezonesByUtcOffsetStr[this.network.timezone]
      console.log(tz)
      if (!tz) return

      return `${tz.name} ${tz.utcOffsetStr}`
    },
  },

  components: {
    NewTerminalDialog,
    ChangeNetworkDialog,
    Confirm,
  },

  methods: {
    ...mapActions('Channel', [ 'getTerminalList', 'removeNetwork' ]),

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearTerminalFilter() {
      this.terminalFilter = ''
    },

    filterTerminalList() {
      const filter = this.terminalFilter?.toLowerCase() ?? ''
      const terminals = this.terminals

      const filteredTerminalList = filter.length == 0
        ? terminals
        : terminals.filter(terminal =>
            terminal.name?.toLowerCase().includes(filter) ||
            terminal.address?.toLowerCase().includes(filter) ||
            terminal.city?.toLowerCase().includes(filter) ||
            terminal.zip?.toLowerCase().includes(filter) ||
            terminal.country?.toLowerCase().includes(filter) ||
            terminal.email?.toLowerCase().includes(filter) ||
            terminal.phone?.toLowerCase().includes(filter) ||
            terminal.cashback_fee?.toString().toLowerCase().includes(filter) ||
            terminal.load_fee?.toString().toLowerCase().includes(filter)
          )

      this.filteredTerminalList = [...filteredTerminalList]
    },

    deleteNetwork() {

      this.$refs.confirm.open(
        'Delete Network',
        'Deletion is permanent. Are you sure?',
      )

      .then(confirmed => {
        if (!confirmed) throw 0;

        this.deleting = true

        return this.removeNetwork(this.network.id)
      })

      .then(() => {
        return this.$router.push({name: 'channel', params: { channel_id: this.channelId }})
      })

      .catch(error => {
        if (!error) return

        this.error = error?.response?.data ?? error?.message

        console.error(this.error)
      })

      .finally(() => {
        this.deleting = false
      })
    },
  },

  mounted() {
    this.getTerminalList()
  },

  watch: {

    '$route.params.channel_id': {
      handler(newValue) {
        this.channelId = newValue
        this.channel = this.channelById(newValue)
      },
      immediate: true,
    },

    '$route.params.network_id': {
      handler(newValue) {
        this.networkId = newValue
        this.network = {...this.networkById(this.networkId)}
        this.filterTerminalList()
      },
      immediate: true,
    },

    channels: {
      handler() {
        this.channel = {...this.channelById(this.channelId)}
      }
    },

    networks: {
      handler() {
        this.network = {...this.networkById(this.networkId)}
      }
    },

    terminals: {
      handler() {
        this.filterTerminalList()
      },
      //immediate: true,
    },

    terminalFilter: {
      handler() { this.filterTerminalList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>


