<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()"
  >

  <template v-slot:activator="{ on: dialog }">
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          fab
          x-small
          v-on="{ ...dialog, ...tooltip }">
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>
      <span>Change Network</span>
    </v-tooltip>
  </template>

  <v-form ref="form" v-model="isValid" @submit.prevent="save">

    <v-card>

      <v-card-title class="headline">
        Change Network

        <v-spacer />

        <v-switch
          v-if="$vuetify.breakpoint.smAndUp"
          v-model="network.active"
          inset
          dark
          :label="network.active ? 'Active' : 'Suspended'" />
      </v-card-title>

      <v-divider />

      <v-card-text>

        <v-container>

          <v-row>
            <v-col cols="12">
              <v-switch
                v-if="$vuetify.breakpoint.xsOnly"
                v-model="network.active"
                inset
                :label="network.active ? 'Active' : 'Suspended'" />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Channel"
                outlined
                disabled
                :value="channel.name"
                />
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                label="Name"
                outlined
                clearable
                inputmode="text"
                v-model="network.name"
                hint="Required"
                persistent-hint
                :rules="[rules.required, rules.min(2)]"
                :background-color="isFieldDirty('name') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Email"
                outlined
                clearable
                inputmode="email"
                v-model="network.email"
                :rules="[rules.emailOrEmpty]"
                :background-color="isFieldDirty('email') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Phone"
                inputmode="tel"
                outlined
                clearable
                v-model="network.phone"
                :background-color="isFieldDirty('phone') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Address"
                inputmode="text"
                outlined
                clearable
                v-model="network.address"
                :background-color="isFieldDirty('address') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="8">
              <v-text-field
                label="City"
                inputmode="text"
                outlined
                clearable
                v-model="network.city"
                :background-color="isFieldDirty('city') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Zip"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.zip"
                :background-color="isFieldDirty('zip') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="network.country"
                :items="countries"
                item-text="name"
                item-value="id"
                label="Country"
                outlined
                :background-color="isFieldDirty('country') ? '#faf9f5' : ''">
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="network.timezone"
                :items="timezones"
                item-text="name"
                item-value="utcOffsetStr"
                label="Timezone"
                outlined
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('timezone') ? '#faf9f5' : ''">
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="network.currency"
                :items="currencies"
                label="Currency"
                outlined
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('currency') ? '#faf9f5' : ''">
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Tax %"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.tax_percentage"
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('tax_percentage') ? '#faf9f5' : ''"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Load Limits
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Minimum"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.load_limit_min"
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('load_limit_min') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Maximum"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.load_limit_max"
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('load_limit_max') ? '#faf9f5' : ''"
                />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Cashback Limits
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Minimum"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.cashback_limit_min"
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('cashback_limit_min') ? '#faf9f5' : ''"
                />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Maximum"
                inputmode="numeric"
                outlined
                clearable
                v-model="network.cashback_limit_max"
                hint="Required"
                persistent-hint
                :rules="[rules.required]"
                :background-color="isFieldDirty('cashback_limit_max') ? '#faf9f5' : ''"
                />
            </v-col>
          </v-row>

          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>

        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

        <v-btn text @click="dialog = false;">Cancel</v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

        <v-btn color="primary" type="submit" :disabled="!canSave" :loading="saving">Save</v-btn>
      </v-card-actions>

    </v-card>

  </v-form>

</v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import ct from 'countries-and-timezones'
import currencyCodes from 'currency-codes'

export default {
  props: ['networkId'],

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      countries: Object.values(ct.getAllCountries()),
      timezones: Object.values(ct.getAllTimezones()),
      currencies: currencyCodes.data.map(c => ({ text: `${c.currency} (${c.code})`, value: c.code })),

      channel: {},
      network: {},

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'networkById']),

    canSave() { return this.isDirty() && this.isValid },
  },

  methods: {
    ...mapActions('Channel', ['saveNetwork']),

    initData() {
      this.network = _.cloneDeep(this.networkById(this.networkId))
      this.channel = _.cloneDeep(this.channelById(this.network.channel_id))
      this.error   = undefined
    },

    isFieldDirty(name) {
      const networkId = this.networkId

      let originalNetwork = this.networkById(networkId)
      if (!originalNetwork) {
        console.error('Could not find original network for comparison', {networkId, name});
      }

      let original = originalNetwork?.[name]?.toString().trim() ?? ''
      let modified = this.network[name]?.toString().trim() ?? ''

      return original != modified
    },

    isDirty() {
      const keysMonitored = ['active', 'name', 'address', 'city', 'zip', 'country', 'email', 'phone', 'timezone', 'currency', 'tax_percentage', 'load_limit_min', 'load_limit_max', 'cashback_limit_min', 'cashback_limit_max'].sort()
      const isDirty = keysMonitored.some(key => this.isFieldDirty(key))

      return isDirty
    },

    save() {
      if (!this.canSave) return

      this.saving = true

      this.saveNetwork(this.network)

      .then(() => { //updatedNetwork => {
        this.saving = false
        this.dialog = false
        //this.$router.push({name: 'network', params: { channel_id: this.channelId, network_id: addedNetwork.id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error?.message
        this.saving = false
      })
    }
  },

  watch: {
    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    },

    //network: {
    //  handler(newValue, oldValue) {
    //    if (newValue.active != oldValue.active) {
    //      this.network.inactive = !this.network.active
    //    }
    //    else {
    //      this.network.active = !this.network.inactive
    //    }
    //  },
    //  deep: true,
    //},
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }
  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }
}
</style>
